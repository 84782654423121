import React                   from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { graphql }             from 'gatsby';
import Img                     from 'gatsby-image';
import { Button }              from '@chakra-ui/react';

import Wrapper       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Link          from '@interness/web-core/src/components/elements/Link/Link';
import HeaderMedia   from '@interness/theme-default/src/components/HeaderMedia';
import Spacer        from '@interness/web-core/src/components/structure/Spacer/Spacer';
import { findMedia } from '@interness/web-core/src/components/_helpers';
import Heading       from '@interness/web-core/src/components/text/Heading/Heading';

function IndexPage(props) {
  return (
    <>
      <HeaderMedia id={'index-5'}/>
      <Spacer/>
      <Wrapper>
        <Heading subtitle={'Herzlich willkommen'}>Meiners + Hinz Hausverwaltung</Heading>
        <section>
          <p>Wir als Hausverwaltung Meiners + Hinz OHG, verwalten und betreuen zur Zeit ca. 750 Wohn- und
            Geschäftseinheiten, davon sind 70% Wohnungseigentümergemeinschaften und 30% private Anleger.</p>
          <p>Unser Tätigkeitsschwerpunkt liegt im Ennepe-Ruhr-Kreis. Daher können wir eine persönliche und
            individuelle Betreuung unserer Kunden sowie eine regelmäßige Begehung der Objekte gewährleisten.</p>
          <p>Unser persönlicher Kontakt zu unseren Eigentümern und auch zu den Mietern ist uns sehr wichtig, um
            die Interessen der Gemeinschaft optimal wahr zu nehmen.</p>
          <p>Wir sind als Mitglied dem BVI Bundesfachverband der Immobilienverwalter e.V. mit Sitz in Berlin
            angeschlossen. Über diesen Verband und weiteren Veranstaltern nehmen wir jährlich an mehreren
            Seminaren und Weiterbildungen teil.</p>
          <p>Die Werte unseres Verbandes bedeuten uns sehr viel und sind uns Verpflichtung zu gleich
            Glaubwürdigkeit Kompetenz Einsatzwille Zuverlässigkeit Partnerschaft Lebensfreude</p>
          <p>
            Wir beraten Sie gerne und unverbindlich, vereinbaren Sie mit uns einen Beratungstermin
            unter Tel. <a href="tel:02332551170">02332 / 551170</a> oder per Mail unter <a
            href="mailto:info@meiners-hinz.de">info@meiners-hinz.de</a>
          </p>
          <p>Ihre Heike Meiners und Sabine Hinz</p>
        </section>
        <Spacer/>
        <Container fluid style={{ textAlign: 'center' }}>
          <Row>
            <Col md={4}>
              <h3>Service</h3>
              <Img fluid={findMedia(props.data.headerImages.images, 'service-2').childImageSharp.fluid} alt="Service"/>
              <Spacer height={30}/>
              <Button colorScheme="brand" variant="solid" as={Link} to="/service">Mehr erfahren</Button>
              <Spacer height={15}/>
            </Col>
            <Col md={4}>
              <h3>Verkauf</h3>
              <Img fluid={findMedia(props.data.headerImages.images, 'sale').childImageSharp.fluid} alt="Zu verkaufen"/>
              <Spacer height={30}/>
              <Button colorScheme="brand" variant="solid" as={Link} to="/zu-verkaufen">Mehr erfahren</Button>
              <Spacer height={15}/>
            </Col>
            <Col md={4}>
              <h3>Vermietung</h3>
              <Img fluid={findMedia(props.data.headerImages.images, 'rent').childImageSharp.fluid} alt="Zu vermieten"/>
              <Spacer height={30}/>
              <Button colorScheme="brand" variant="solid" as={Link} to="/zu-vermieten">Mehr erfahren</Button>
              <Spacer height={15}/>
            </Col>
          </Row>
        </Container>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default IndexPage;

export const query = graphql`
    query {
        headerImages: directusMediaCollection(name: {eq: "headers"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 400, maxHeight: 200, quality: 95) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;